<template>
  <div>
    <div class="game-jams p-4">
      <div class="grid gap-2">
        <p class="font-InterSemiBold text-xs">Step 2/6</p>
      </div>
      <div class="mt-4">
        <div class="rounded-3xl bg-white p-4">
          <p class="font-InterBold text-xl">
            Let's jazz up your Playda! experience by adding your company logo.
          </p>
          <p class="font-InterSemiBold text-xs text-nColorLightGray">
            Upload your Company logo in JPG or PNG, The images on the right
            represents the importance of Logo.
          </p>
          <div class="mt-4 flex">
            <div v-if="themeMobileSelection === false" class="w-full">
              <label
                class="grid justify-items-center rounded-xl border border-dotted border-nColorBorderOrange p-10"
                for="logo"
                v-if="!uploadingMobileLogo"
              >
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/diy/common/upload_icon.webp"
                  alt="DIY Upload Icon"
                  width="120"
                  height="114"
                />
                <p class="font-InterSemiBold text-nColorCopyrightGray">
                  Upload your logo
                </p>
                <div>
                  <input
                    accept="image/jpg,image/jpeg,image/png]"
                    ref="logo"
                    type="file"
                    name="logo"
                    id="logo"
                    class="hidden"
                    @change="logoSelected($event)"
                  />
                  <span
                    class="bg-nColorOrange rounded-3xl px-4 py-1 text-white text-center text-xs font-InterSemiBold tracking-wider focus:outline-none"
                  >
                    Browse
                  </span>
                </div>
              </label>
              <div v-else>
                <div
                  class="grid justify-items-center rounded-xl border border-dotted border-nColorBorderOrange p-10 h-60"
                >
                  <span class="flex justify-center items-center">
                    <svg
                      width="80"
                      height="20"
                      viewBox="0 0 120 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000"
                    >
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="w-full" v-else>
              <div
                class="grid justify-items-center rounded-xl border border-dotted border-nColorBorderOrange p-10"
                v-if="!uploadingMobileLogo"
              >
                <label for="logo">
                  <img
                    :src="logo"
                    alt="Uploaded logo"
                    width="120"
                    height="120"
                    class="border border-nColorLightGray rounded-xl"
                  />
                  <div>
                    <input
                      accept="image/jpg,image/jpeg,image/png]"
                      ref="logo"
                      type="file"
                      name="logo"
                      id="logo"
                      class="hidden"
                      @change="logoSelected($event)"
                    />
                    <div class="mt-2">
                      <span
                        class="bg-nColorOrange rounded-3xl px-4 py-1 text-white text-center text-sm font-InterSemiBold tracking-wider focus:outline-none"
                      >
                        Change Logo
                      </span>
                    </div>
                  </div>
                </label>
                <div>
                  <a
                    @click.prevent="openModal"
                    class="text-nColorOrange font-InterSemiBold text-sm"
                    >Remove logo</a
                  >
                </div>
              </div>
              <div v-else>
                <div
                  class="grid justify-items-center rounded-xl border border-dotted border-nColorBorderOrange p-10 h-60"
                >
                  <span class="flex justify-center items-center">
                    <svg
                      width="80"
                      height="20"
                      viewBox="0 0 120 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000"
                    >
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="rounded-3xl bg-white p-4 mt-4"
        v-if="themeMobileSelection === true"
      >
        <p class="font-InterBold text-xl">
          Select a theme to personalize the platform.
        </p>
        <p class="font-InterSemiBold text-xs text-nColorLightGray">
          Explore Interface Theme Options for Personalizing the Platform.
        </p>
        <div class="mt-4 flex gap-4">
          <div>
            <label class="inline-flex items-center">
              <input
                type="radio"
                value="dark"
                v-model="selectedTheme"
                class="mr-1 h-4 w-4"
              />
              <span class="font-InterMedium text-sm">Dark UI</span>
            </label>
            <div class="flex gap-2 items-end">
              <div>
                <img
                  :src="darkImage"
                  alt="Dark UI Image"
                  width="140"
                  height="300"
                />
              </div>
            </div>
          </div>
          <div class="ml-4">
            <label class="inline-flex items-center">
              <input
                type="radio"
                value="light"
                v-model="selectedTheme"
                class="mr-1 h-4 w-4"
              />
              <span class="font-InterMedium text-sm">Light UI</span>
            </label>
            <div class="flex gap-2 items-end">
              <div>
                <img
                  :src="lightImage"
                  alt="Light UI Image"
                  width="140"
                  height="300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-2 gap-2 pb-6">
        <button
          @click.prevent="skipStep()"
          class="border border-black rounded-3xl px-4 py-1 text-black text-center uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          <span> Skip </span>
        </button>
        <button
          @click.prevent="updateLogoTheme()"
          class="bg-black rounded-3xl px-4 py-1 text-white text-center uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          <span> Next </span>
        </button>
      </div>
    </div>
    <ConfirmationModal
      @closeModal="closeModal"
      @remove="removeLogo()"
      :modal="modal"
    />
  </div>
</template>
      
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";
import ConfirmationModal from "@/components/Elements/ConfirmationModal.vue";

const toast = useToast();

export default {
  name: "GameJamsMobileStep2",
  components: { ConfirmationModal },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: null,
      // logo: "https://s3-ap-south-1.amazonaws.com/playda/game-jams/66434a5f66ce70fce0f5dd1b/logo-square-1715687125.1524491.png",
      // themeMobileSelection: true,
      // darkImage:
      //   "https://s3-ap-south-1.amazonaws.com/playda/game-jams/66434a5f66ce70fce0f5dd1b/dark-1715687125.1524491.png",
      // lightImage:
      //   "https://s3-ap-south-1.amazonaws.com/playda/game-jams/66434a5f66ce70fce0f5dd1b/light-1715687125.1524491.png",
      logo: null,
      themeMobileSelection: false,
      darkImage: null,
      lightImage: null,
      selectedTheme: "dark",
      modal: {
        show: false,
        heading: "Remove logo",
        question: "Are you sure you want to remove your logo?",
        action: "remove",
        showLottie: true,
        lottiePath: "lotties/warning.json",
      },
      uploadingMobileLogo: false,
    };
  },
  created() {
    if (this.gameJamData.step2 && this.gameJamData.step2 != "skip") {
      this.logo = this.gameJamData.step2.logo_square;
      this.themeMobileSelection = true;
      this.darkImage = this.gameJamData.step2.dark;
      this.lightImage = this.gameJamData.step2.light;
    }
  },
  mounted() {},
  computed: {},
  methods: {
    openModal() {
      const body = document.body;
      body.style.overflow = "hidden";
      this.modal.show = true;
    },
    closeModal() {
      const body = document.body;
      body.style.overflow = "";
      this.modal.show = false;
    },
    logoSelected(e) {
      console.log("in");
      this.uploadingMobileLogo = true;
      this.themeMobileSelection = false;
      this.darkImage = null;
      this.lightImage = null;
      this.formData = new FormData();
      this.formData.append("logo", e.target.files[0]);
      this.formData.append("game_jam_id", this.$route.params.game_jam_id);
      this.formData.append("step", this.$route.params.step);
      ApiService.simplePost(
        apiResource.gameJams.uploadLogo,
        this.formData
      ).then((data) => {
        this.logo = data.data.data.logo_square;
        this.themeMobileSelection = true;
        this.darkImage = data.data.data.dark;
        this.lightImage = data.data.data.light;
        this.uploadingMobileLogo = false;
        console.log(this.themeMobileSelection);
      });
    },
    removeLogo() {
      ApiService.post(apiResource.gameJams.removeLogo, {
        game_jam_id: this.$route.params.game_jam_id,
        step: this.$route.params.step,
      }).then(() => {
        this.logo = null;
        this.themeMobileSelection = false;
        this.darkImage = null;
        this.lightImage = null;
      });
    },
    updateLogoTheme() {
      if (!this.logo) {
        toast.error("Please upload a logo to proceed", { timeout: 2000 });
      } else {
        ApiService.post(apiResource.gameJams.updateTheme, {
          game_jam_id: this.$route.params.game_jam_id,
          step: this.$route.params.step,
          theme: this.selectedTheme,
        }).then(() => {
          this.$router.push({
            name: "CreateGameJam",
            params: {
              game_jam_id: this.$route.params.game_jam_id,
              step: "step3",
            },
          });
        });
      }
    },
    skipStep() {
      ApiService.post(apiResource.gameJams.skipStep, {
        game_jam_id: this.$route.params.game_jam_id,
        step: this.$route.params.step,
      }).then(() => {
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: this.$route.params.game_jam_id,
            step: "step3",
          },
        });
      });
    },
  },
};
</script>
      
<style scoped lang="postcss">
.game-jams {
  height: 100dvh;
}
.textInput {
  @apply w-full text-black pt-2 pb-3 border-b border-nColorDarkGray focus:outline-none focus:border-gray-500 bg-transparent;
}
</style>
      