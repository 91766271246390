<template>
  <div class="p-4">
    <div class="flex gap-2 items-center">
      <p class="font-ZuumeSemiBold text-5xl text-nColorOrange">Hello!</p>
      <div>
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/diy/wizard/smiley.webp"
          alt="Game Jam Smiley"
          width="40"
          height="40"
        />
      </div>
    </div>
    <p class="font-InterSemiBold text-xs md:text-sm">
      Let's plan your first Ultimate Engagement Game Jam!
    </p>
    <hr class="mt-2 border-nColorLightGray" />
  </div>
</template>
  
  <script>
export default {
  name: "GameJamHeader",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
  
<style scoped></style>