<template>
  <div class="dashboard">
    <div class="flex">
      <div class="w-full">
        <Header />
        <div v-if="gameJamData">
          <Wizard :gameJamData="gameJamData" />
        </div>
        <div v-if="gameJamData">
          <component :is="step" :gameJamData="gameJamData"> </component>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Header from "@/components/CreateGameJam/Header.vue";
import Wizard from "@/components/CreateGameJam/MobileWizard.vue";
import step1 from "@/components/CreateGameJam/Steps/Step1/Mobile.vue";
import step2 from "@/components/CreateGameJam/Steps/Step2/Mobile.vue";
import WizardSteps from "@/common/wizard.config";

export default {
  name: "CreateGameJam",
  components: { Header, Wizard, step1, step2 },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: "step1",
      WizardSteps,
      currentStep: this.$route.params.step,
    };
  },
  created() {
    this.step = this.currentStep;
  },
  mounted() {},
  computed: {},
  methods: {
    discoverGames() {
      console.log("discover games");
    },
  },
};
</script>
  
<style scoped lang="postcss">
</style>
  