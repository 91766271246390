<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto text-gray-800 z-40">
      <div
        class="flex items-end justify-center mobile-height md:h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 relative"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-80"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white">
            <div>
              <div
                class="flex justify-between text-3xl font-semibold text-white bg-blue-900 px-8 py-4"
              >
                <div>
                  <h3 id="modal-headline">
                    <div class="flex flex-row items-center gap-2">
                      <div class="text-2xl">
                        {{ modal.heading }}
                      </div>
                    </div>
                  </h3>
                </div>
              </div>
              <div
                class="mt-2 px-8 py-1 pb-3 bg-white text-base flex gap-2 font-medium items-center"
              >
                <div v-if="modal.showLottie">
                  <lottie-animation
                    :path="modal.lottiePath"
                    :loop="true"
                    :autoPlay="true"
                    :speed="1"
                    :width="50"
                    :height="50"
                  />
                </div>
                <div>{{ modal.question }}</div>
              </div>
              <div class="flex justify-end gap-4 px-8 pt-2 pb-6">
                <button
                  @click="closeModal()"
                  type="button"
                  class="text-sm uppercase tracking-wider transform transition-all duration-400 ease-in-out outline-none focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  @click="actionType(modal.action)"
                  type="button"
                  class="font-semibold px-6 rounded-lg py-2 text-sm uppercase tracking-wider transform transition-all duration-400 ease-in-out bg-blue-700 text-white hover:bg-blue-600 hover:font-bold outline-none focus:outline-none"
                >
                  {{ modal.action }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "ConfirmationModal",
  components: { LottieAnimation },
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    actionType(action) {
      this.$emit("closeModal", false);
      this.$emit(action);
    },
  },
};
</script>

<style scoped>
.mobile-height {
  height: 75%;
}
</style>
