<template>
  <div class="menu w-72 h-screen relative">
    <div class="fixed bg-white w-72 h-screen pl-8 py-8">
      <div class="p-8">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp"
          alt="Playda Logo"
          width="150"
          height="53"
        />
      </div>
      <div class="grid">
        <div v-for="(step, index) in wizard.steps" :key="index">
          <div class="flex gap-2 items-center my-2">
            <span
              class="w-5 h-5 rounded-full bg-nColorOrange text-xs font-InterBold flex justify-center items-center text-white"
              v-if="isCurrentStep(step.slug)"
              >{{ index + 1 }}</span
            ><span
              class="w-5 h-5 rounded-full bg-nColorGreen text-xs font-InterBold flex justify-center items-center text-white"
              v-else-if="isStepComplete(step.slug)"
              >&#10003;</span
            ><span
              v-else
              class="w-5 h-5 rounded-full bg-nColorLightGray text-xs font-InterBold flex justify-center items-center text-white"
              >{{ index + 1 }}</span
            >
            <p class="font-InterSemiBold text-sm text-nColorOrange">
              {{ step.text }}
            </p>
          </div>
        </div>
      </div>
      <div class="absolute bottom-8 w-9/12">
        <div class="grid justify-items-center">
          <p class="font-InterBold text-xl text-nColorOrange">{{ username }}</p>
          <p class="font-InterMedium text-nColorDarkGray opacity-60 text-sm">
            {{ email }}
          </p>
          <div>
            <button
              class="font-ZuumeMedium border border-black px-6 mt-4 rounded-xl"
              @click.prevent="signOut()"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wizard from "@/common/wizard.config";

export default {
  components: {},
  name: "DesktopMenu",
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
      wizard,
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    isStepComplete(slug) {
      if (
        Object.hasOwnProperty.call(this.gameJamData, slug) &&
        this.gameJamData[slug] !== null
      ) {
        return true;
      }

      return false;
    },
    isCurrentStep(slug) {
      if (this.$route.params.step === slug) {
        return true;
      }
      return false;
    },
  },
};
</script>
  
  <style scoped>
.menu {
  height: 100dvh;
}
.top-half-border {
  border-image: linear-gradient(to bottom, transparent 50%, #f67134 50%) 0 1;
}
.bottom-half-border {
  border-image: linear-gradient(to bottom, #f67134 50%, transparent 50%) 0 1;
}
</style>