export default {
    steps: [
      {
        slug: "step1",
        text: "Name your Game Jam",
      },
      {
        slug: "step2",
        text: "Upload Logo",
      },
      {
        slug: "step3",
        text: "Game count, Duration and Rewards",
      },
      {
        slug: "step4",
        text: "Rewards Selection",
      },
      {
        slug: "step5",
        text: "Add Games",
      },
      {
        slug: "step6",
        text: "Download",
      },
    ]
  };
  