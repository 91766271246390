<template>
  <div class="px-4 relative">
    <div class="flex overflow-x-auto gap-3">
      <div v-for="(step, index) in wizard.steps" :key="index">
        <div
          :class="{
            'border-b-2 border-nColorOrange': isCurrentStep(step.slug),
          }"
          class="flex items-center"
        >
          <div>
            <span
              class="w-5 h-5 text-xs font-InterBold flex justify-center items-center text-nColorOrange"
              v-if="isCurrentStep(step.slug)"
              >{{ index + 1 }}.</span
            ><span
              class="w-5 h-5 text-xs font-InterBold flex justify-center items-center text-nColorGreen"
              v-else-if="isStepComplete(step.slug)"
              >&#10003;</span
            ><span
              v-else
              class="w-5 h-5 text-xs font-InterBold flex justify-center items-center text-nColorOrange"
              >{{ index + 1 }}.</span
            >
          </div>
          <div class="whitespace-nowrap">
            <p class="font-InterSemiBold text-xs text-nColorOrange">
              {{ step.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import wizard from "@/common/wizard.config";

export default {
  name: "DesktopMenu",
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
      wizard,
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    isStepComplete(slug) {
      if (
        Object.hasOwnProperty.call(this.gameJamData, slug) &&
        this.gameJamData[slug] !== null
      ) {
        return true;
      }

      return false;
    },
    isCurrentStep(slug) {
      if (this.$route.params.step === slug) {
        return true;
      }
      return false;
    },
  },
};
</script>
    
<style scoped></style>