<template>
  <div class="dashboard">
    <div class="flex">
      <div v-if="gameJamData">
        <Wizard :gameJamData="gameJamData" />
      </div>
      <div class="w-full relative" v-if="gameJamData">
        <Header />
        <component :is="step" :gameJamData="gameJamData"> </component>
      </div>
    </div>
  </div>
</template>
  
<script>
import Header from "@/components/CreateGameJam/Header.vue";
import Wizard from "@/components/CreateGameJam/Wizard.vue";
import step1 from "@/components/CreateGameJam/Steps/Step1/Desktop.vue";
import step2 from "@/components/CreateGameJam/Steps/Step2/Desktop.vue";
import WizardSteps from "@/common/wizard.config";

export default {
  name: "CreateGameJam",
  components: { Header, Wizard, step1, step2 },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: "step1",
      WizardSteps,
      currentStep: this.$route.params.step,
    };
  },
  created() {
    this.step = this.currentStep;
    // for (let i = 1; i <= 5; i++) {
    //   if (!this.gameJamData["step" + i]) {
    //     this.step = "step" + i.toString();
    //     if (this.step != this.currentStep) {
    //       this.$router.push({
    //         name: "CreateGameJam",
    //         params: {
    //           game_jam_id: this.$route.params.game_jam_id,
    //           step: this.step,
    //         },
    //       });
    //     }
    //     break;
    //   }
    // }
  },
  mounted() {},
  computed: {},
  methods: {
    discoverGames() {
      console.log("discover games");
    },
  },
};
</script>
  
  <style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
</style>
  