<template>
  <div class="p-4">
    <div class="grid gap-2">
      <p class="font-InterSemiBold text-xs">Step 1/6</p>
    </div>
    <div class="mt-4 bg-white rounded-xl p-4 border border-nColorBorderOrange">
      <p class="font-InterBold text-lg">
        To kick things off, let's name your Game Jam!
      </p>
      <p class="font-InterSemiBold text-xs text-nColorCopyrightGray">
        We'll use this name as needed on the Playda! platform.
      </p>
      <input
        type="text"
        name="gameJamName"
        id="gameJamName"
        v-model="gameJamName"
        class="textInput"
        placeholder="Name your Game Jam"
      />
    </div>
    <div class="w-full absolute left-0 bottom-6">
      <div class="px-4 flex w-full">
        <button
          @click.prevent="updateGameJamName()"
          class="bg-black rounded-3xl w-full px-4 py-1 text-white text-center uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          <span> Next </span>
        </button>
      </div>
    </div>
  </div>
</template>
      
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "GameJamsMobileStep1",
  components: {},
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gameJamName: null,
    };
  },
  created() {},
  mounted() {
    if (this.gameJamData.step1) {
      this.gameJamName = this.gameJamData.step1;
    }
  },
  computed: {},
  methods: {
    updateGameJamName() {
      ApiService.post(apiResource.gameJams.saveProgress, {
        step: this.$route.params.step,
        game_jam_id: this.$route.params.game_jam_id,
        data: this.gameJamName,
      }).then((data) => {
        var dataResponse = data.data.data;
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: dataResponse.game_jam_id,
            step: "step2",
          },
        });
      });
    },
  },
};
</script>
      
<style scoped lang="postcss">
.textInput {
  @apply w-full text-black pt-2 pb-3 border-b border-nColorDarkGray focus:outline-none focus:border-gray-500 bg-transparent;
}
</style>
      