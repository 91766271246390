<template>
  <div v-if="wizardData">
    <Mobile v-if="isMobile" :gameJamData="wizardData" />
    <Desktop v-else :gameJamData="wizardData" />
  </div>
</template>
  
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Desktop from "@/components/CreateGameJam/Desktop.vue";
import Mobile from "@/components/CreateGameJam/Mobile.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "CreateGameJam",
  components: { Desktop, Mobile },
  data() {
    return {
      isMobile: false,
      wizardData: null,
    };
  },
  created() {
    if (this.$route.params.game_jam_id != "-1") {
      ApiService.get(apiResource.gameJams.getDetails, {
        game_jam_id: this.$route.params.game_jam_id,
      })
        .then((response) => {
          this.wizardData = response.data.data;
        })
        .catch((error) => {
          if (error.response.data.msg === "There is no game jam available") {
            toast.error(
              "There is no game jam available. Redirecting you to the dashboard.",
              { timeout: 2000 }
            );
            setTimeout(() => {
              this.$router.replace({
                name: "Dashboard",
              });
            }, 2000);
          }
        });
    } else {
      this.wizardData = {
        game_jam_id: "-1",
        step1: null,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        step6: null,
      };
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    window.addEventListener("resize", this.handleResize);
  },
  computed: {},
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
    
  <style scoped></style>
  